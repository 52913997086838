import React from "react";
import interceptor from "./interceptor/interceptor";
import { ArkoseAdaptor } from "./adaptor/arkoseAdaptor";
import { EventDataType } from "./adaptor/baseAdaptor";
import { MiscUtil } from "./utils/miscUtils";
import * as Constants from "./constant/constants";

export interface IProtocolInput {
  env: string;
}

// sample url from challenge handler: https://www.pay.microsoft.com/challengeui?challenge_session_id=adff3815-10a7-4134-8c54-74ece2b3f22d&challenge_id=565fdede-e0c7-4b57-9672-aecd0415bea5&service_type=Arkose&public_key=E3375B74-54CD-4C1F-AB17-4C0C5303C2DA &company_name=microsoft&blob=AQHS8eMYB77wpCW1hbqWEeTQacOXzHA9yZbvFtcQFmkh6twPw3BLUf+KrsV/tq2IGTs7KQxaVB+dt07cghk2HyiXkFEYT8DJNxGLOmsohON3sIx/uPWb6P8D64d0o/iGTxkHXHLkdettn9mNKQ/nqea7Cur+uxOOAojVwtGxUsNdLrWjEil7+4bdk6hZ62k9kZqQ2+IS6DiNWoR/XlOhZT2w
// local sample url: http://localhost:3000/challengeui?challenge_session_id=adff3815-10a7-4134-8c54-74ece2b3f22d&challenge_id=565fdede-e0c7-4b57-9672-aecd0415bea5&service_type=Arkose&public_key=E3375B74-54CD-4C1F-AB17-4C0C5303C2DA&company_name=microsoft&blob=AQHS8eMYB77wpCW1hbqWEeTQacOXzHA9yZbvFtcQFmkh6twPw3BLUf+KrsV/tq2IGTs7KQxaVB+dt07cghk2HyiXkFEYT8DJNxGLOmsohON3sIx/uPWb6P8D64d0o/iGTxkHXHLkdettn9mNKQ/nqea7Cur+uxOOAojVwtGxUsNdLrWjEil7+4bdk6hZ62k9kZqQ2+IS6DiNWoR/XlOhZT2w

const challengeManagerSubmit = (
  data: EventDataType,
  eventType: string,
  challengeProviderName: string,
  env: string,
  cid: string
) => {
  return interceptor.post(
    `https://${env}`,
    {
      session_id: data.sessionId,
      challenge_provider_name: challengeProviderName,
      challenge_details: {
        event_type: eventType,
        event_details: {
          session_token: data.token,
        },
      },
    },
    {
      headers: {
        "Accept-Language": "en-us",
        "Content-Type": "application/json",
        "x-ms-correlation-id": cid,
        Accept: "application/json",
        Blob: data.encryptedBlob,
        Challenge_provider_name: challengeProviderName,
        Challenge_session_id: data.sessionId,
      },
    }
  );
};

export const ChallengeProtocol = () => {
  const challengeProviderName = MiscUtil.getQueryStringValue(
    Constants.challengeProviderParamName
  );
  const envName = MiscUtil.getQueryStringValue(Constants.envName);
  const cid = MiscUtil.getQueryStringValue(Constants.correlationId);

  const onCompleted = (data: EventDataType) => {
    console.log("the oncompleted event data is ", data);

    if (
      MiscUtil.caseInsensitiveStringComparison(
        challengeProviderName,
        Constants.ArkoseServiceType
      )
    ) {
      challengeManagerSubmit(
        data,
        "completed",
        challengeProviderName,
        envName,
        cid
      )
        .then((res) => {
          console.log("submit completed event succeed: ", res.data);
        })
        .catch((err) => {
          console.error("submit completed event failed: ", err);
        });
    }
  };

  // TODO: need to test if this onFailed request is going through
  const onFailed = (data: EventDataType) => {
    console.log("the onfailed event data is ", data);

    if (
      MiscUtil.caseInsensitiveStringComparison(
        challengeProviderName,
        Constants.ArkoseServiceType
      )
    ) {
      challengeManagerSubmit(
        data,
        "failed",
        challengeProviderName,
        envName,
        cid
      )
        .then((res) => {
          console.log("submit failed event succeed: ", res.data);
        })
        .catch((err) => {
          console.error("submit failed event failed: ", err);
        });
    }
  };

  if (
    MiscUtil.caseInsensitiveStringComparison(
      challengeProviderName,
      Constants.ArkoseServiceType
    )
  ) {
    const challengeSessionId = MiscUtil.getQueryStringValue(
      Constants.challengeSessionIdParamName
    );
    const challengeId = MiscUtil.getQueryStringValue(
      Constants.challengeIdParamName
    );
    const publicKey = MiscUtil.getQueryStringValue(
      Constants.publicKeyParamName
    );
    const companyName = MiscUtil.getQueryStringValue(
      Constants.companyNameParamName
    );
    // replace all spaces with "+", somehow URLSearchParams() does not preserve plus signs https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#preserving_plus_signs
    const encryptedBlob = MiscUtil.getQueryStringValue(
      Constants.encryptedBlobParamName
    ).replaceAll(" ", "+");

    // return error if any of the params is a empty string
    if (
      MiscUtil.isNullOrUndefinedOrEmptyString(challengeSessionId) ||
      MiscUtil.isNullOrUndefinedOrEmptyString(challengeId) ||
      MiscUtil.isNullOrUndefinedOrEmptyString(publicKey) ||
      MiscUtil.isNullOrUndefinedOrEmptyString(companyName) ||
      MiscUtil.isNullOrUndefinedOrEmptyString(encryptedBlob)
    ) {
      // TODO: what should happen here? should I terminate the program or retry?
      console.error("Required param is missing for Arkose adaptor");
      return (
        <div>Parameter parsing errro, unable to load Arkose challenge</div>
      );
    }

    return (
      <ArkoseAdaptor
        challengeSessionId={challengeSessionId}
        challengeId={challengeId}
        publicKey={publicKey}
        companyName={companyName}
        encryptedBlob={encryptedBlob}
        onCompleted={onCompleted}
        onFailed={onFailed}
      />
    );
  } else {
    return <div>Unable to load challenge</div>;
  }
};
