import { ApplicationInsights, IExtendedConfiguration, IWebAnalyticsConfiguration } from "@microsoft/1ds-analytics-web-js";
import { IChannelConfiguration } from '@microsoft/1ds-post-js';
import { Cv } from "@microsoft/1ds-cv-js";
import { newGuid } from "./helpers/Guid";

export interface IEventHub {
    trackEvent(eventName: string, parameters: {[key: string]: any}): void;
    getSessionId(): string;
}

export class EventHub implements IEventHub {
    private telemetryContext?: {[key: string]: any} = undefined;
    private onEvent;
    private operationId:  string | null;
    private sessionId;
    private appInsights;
    private cv;

    constructor(telemetryContext?: {[key: string]: any}, onEvent?: (eventName: string, eventParams: { [key: string]: any }) => boolean | void) {
        this.telemetryContext = telemetryContext;
        this.onEvent = onEvent;
        this.sessionId = newGuid();
        this.appInsights = new ApplicationInsights();
        this.initializeTelemetryLogger();
        const initValue : string = (
          telemetryContext &&
          telemetryContext.partnerContext &&
          telemetryContext.partnerContext.cv) ?
          telemetryContext.partnerContext.cv : "";
        this.cv = new Cv(initValue, this.appInsights);
        this.operationId = null;
    }

    incrementCv = (): void => {
      this.cv.increment();
    }

    getCv = (): string => {
      return this.cv.getValue();
    }

    trackEvent = (eventName: string, eventProperties: {[key: string]: any}, startNewOperation?: boolean, endOperation?: boolean, eventContext?: any): void => {
      if (startNewOperation) {
          this.operationId = newGuid();
      }

      const customProperties = {Cv: this.getCv(), sessionId: this.sessionId, operationId: this.operationId, telemetryContext: this.telemetryContext, eventName, eventProperties, eventContext};

      this.appInsights.trackEvent({name: "Microsoft.Commerce.Payments.ThirdPartyPayments.PageEvent"}, customProperties);


      if (endOperation) {
          this.operationId = null;
      }

      // call onEvent callback method
      if (this.onEvent) {
        this.onEvent(eventName, eventProperties);
      }
      
    }

    getSessionId = (): string => {
        return this.sessionId;
    }

    private initializeTelemetryLogger = (): void => {
        // Configure ApplicationInsights
        const instrumentationKey = "4e174602182249838fba257228d5bf8c-c717965d-5d08-44b3-bdc3-e4527211077b-7248";
        const webAnalyticsConfig: IWebAnalyticsConfiguration = {
          autoCapture: {
            pageView: true,
            click: false,
            scroll: true,
            onUnload: true,
          },
        };

        const channelConfig: IChannelConfiguration = {
          ignoreMc1Ms0CookieProcessing: true
        };

        const config: IExtendedConfiguration = {
          instrumentationKey: instrumentationKey,
          disableCookiesUsage: true,
          channelConfiguration: channelConfig,
          // Extra extensions
          extensions: [],
          cookieCfg: {
            enabled: false
          },
          webAnalyticsConfiguration: webAnalyticsConfig,
        };

        // Initialize SDK
        this.appInsights.initialize(config, []);

        // Add listener for events send
        this.appInsights.addNotificationListener({
          eventsSent: function (events) {
            events.forEach(event => {
              console.log("Event sent " + event.name);
            });
          },
          eventsDiscarded: function (events) {
            events.forEach(event => {
              console.log("Event discarded " + event.name);
            });
          },
        });
    }
}
