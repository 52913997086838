export const envName = "env";
export const challengeSessionIdParamName = "challenge_session_id";
export const challengeIdParamName = "challenge_id";
export const challengeProviderParamName = "challenge_provider_name";
export const publicKeyParamName = "public_key";
export const companyNameParamName = "company_name";
export const correlationId = "cid";
export const encryptedBlobParamName = "blob";
export const ArkoseServiceType = "Arkose";
export const defaultTimeout = 300;
