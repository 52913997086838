// Extending JSON to add prune. Used in prettifyJson().
// This is using JSON.prune.js (in external-scripts folder).
// See: https://github.com/Canop/JSON.prune
// Misc utility class. Contains general purpose helper functions.

export class MiscUtil {
  private static guidPattern = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";

  // Check if variable is null or undefined. Not checking for empty string or 0 (both are falsey).
  public static isNullOrUndefined(x?: any): boolean {
    if (x === null) {
      return true;
    } else if (x === undefined) {
      return true;
    } else if (x === "undefined") {
      return true;
    }
    return false;
  }

  // Check if variable is null or undefined or empty object.
  public static isNullOrUndefinedorEmptyObject(x?: any): boolean {
    if (x === null) {
      return true;
    } else if (x === undefined) {
      return true;
    } else if (Object.keys(x).length === 0) {
      return true;
    }
    return false;
  }

  // Check if variable is null or undefined or empty array. Not checking for empty string or 0 (both are falsey).
  public static isNullOrUndefinedOrEmptyArray(x?: any): boolean {
    if (x === null) {
      return true;
    } else if (x === undefined) {
      return true;
    } else if (x.length === 0) {
      return true;
    }
    return false;
  }

  // Check if variable is null or undefined or empty string. Does not check for 0 (which is falsey).
  public static isNullOrUndefinedOrEmptyString(x?: any): boolean {
    if (MiscUtil.isNullOrUndefined(x)) {
      return true;
    } else if (typeof x === "string" && x.trim() === "") {
      return true;
    }
    return false;
  }

  // Get a query string value for specified key (case insensitive).
  // See: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
  public static getQueryStringValue(key: string): string {
    // parse the query params in to a hashmap for further processing
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    for (const x of Object.keys(params)) {
      // case insensitive comparison
      if (x.toLowerCase() === key.toLowerCase()) {
        return params[x];
      }
    }
    return "";
  }

  // Create a new guid.
  public static createGuid(): string {
    return MiscUtil.guidPattern.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  // compare if two case insensitive strings are equal
  public static caseInsensitiveStringComparison(x: string, y: string): boolean {
    if (MiscUtil.isNullOrUndefined(x)) {
      return false;
    } else if (MiscUtil.isNullOrUndefined(y)) {
      return false;
    }
    return x.toLowerCase() === y.toLowerCase();
  }
}
